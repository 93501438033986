.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0rem;
  font-weight: 500;
  line-height: 0;
  color: var(--bs-heading-color);
}

// ----------------------------------------------------
.footer {
  width: 100vw;
  position: relative;
  // margin-top: 2.5em;
  background-color: $primaryGreyBG;
  display: flex;
  flex-direction: column;
  align-items: center;
  .contents {
    padding-top: 3em;
    padding-bottom: 1em;
    width: 1269px;
    display: flex;
    justify-content: center;
    .bar-wrapper {
      display: flex;
      justify-content: center;
      width: 98%;
      display: flex;
      gap: 5px;
      flex-direction: column;
      align-items: center;
      .text {
        text-align: center;
        cursor: pointer;
        color: grey;
        font-size: 16px;
      }
      @media screen and (max-width: 500px) {
        .text {
          font-size: 13px;
        }
      }
      .divider-line {
        width: 100%;
        border-bottom: 1px solid #000;
      }
    }
    .social-icons {
      top: -1.4em;
      position: absolute;
      display: flex;
      .icons-wrapper {
        margin-left: 1em;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $primaryGrey;
        border-radius: 50%;
        width: 44px;
        height: 44px;
        cursor: pointer;
        .icon {
          color: $primaryGrey;
          width: 20.5px;
          height: 20.5px;
        }
      }
    }
  }

  @media screen and (max-width: 1270px) {
    .contents {
      width: 100%;
    }
  }
  .bottom-bar-img {
    margin-top: 0.4em;
    width: 100%;
    width: 1269px;
  }
  @media screen and (max-width: 1270px) {
    .bottom-bar-img {
      width: 100%;
    }
  }
}
