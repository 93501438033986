.overlay {
    width: 100%;
    position: fixed;
    background-color: rgb(255, 255, 255);
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    color: #fff;
    .close-icon {
      width: 25px;
      height: 25px;
      position: absolute;
      right: 1em;
      top: 1em;
      cursor: pointer;
    }
    .text-wrapper {
      text-align: justify;
      margin-top: 5em;
      padding: 0 1em;
    }
  }
  
  // Global styles and transitions
  .timeline-transition {
    transition: background-image 0.5s ease-in-out;
  }
  
  // Timeline Wrapper
  .timeline-wrapper {
    height: max-content;
    width: max-content;
    //
    background-color: rgba(0, 0, 0, 0.573);
    position: relative;
    // Timeline Background
    width: 100%;
    .timeline-background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: -1;
      background-size: cover;
      background-position: center;
      transition: opacity 0.1s ease-in, background-image 0.3s ease-in; /* Adjust durations as needed */
      opacity: 1;
    }
    .timeline {
      width: 100%;
      overflow: hidden;
      transition: background-image 0.5s ease-in-out;
      .columns-wrapper {
        display: flex;
        justify-content: center;
        .years-inner-wrapper {
          width: max-content;
          display: flex;
          .years-inner {
            font-family: Roboto;
            font-style: italic;
            display: flex;
            flex-direction: column;
            padding: 8em 0;
            height: 100%;
            .dot-year-wrapper {
              display: flex;
              flex-direction: column;
              // background-color: red;
              // Year
              .year {
                color: #ffffff73;
                align-items: center;
                display: flex;
                justify-content: flex-end;
                gap: 1em;
                min-width: 500px;
                display: flex;
                // background-color: green;
                // cards vertical padding
                margin: 3em 0;
                cursor: pointer;
                font-size: 18px;
                font-weight: 600;
                transition: font-size 0.5s;
                height: 100px;
                // Selected Year
                &.selected {
                  color: #fff;
                  transition: font-size 0.5s;
                  font-weight: 600;
                  font-size: 3em;
                  gap: 0.4em;
                }
                // Dot
                .dot {
                  color: #fff;
                  font-size: 40px;
                  margin-right: -0.5em;
                }
                .content-text {
                  margin-left: 1em;
                  // margin-right: 1em;
                  border-radius: 5px;
                  padding: 30px 22px;
                  background-color: #fff;
                  color: #000;
                  font-family: Roboto;
                  font-weight: 400px;
                  font-size: 14px;
                  width: 400px;
                  text-decoration: normal;
                  font-style: normal;
                  transition: font-size 0.5s, color 0.5s, opacity 0.5s;
                }
                @media screen and (max-width: 550px) {
                  .content-text {
                    padding: 15px 15px;
                    width: 350px;
                  }
                }
                @media screen and (max-width: 460px) {
                  .content-text {
                    width: 300px;
                  }
                }
                @media screen and (max-width: 400px) {
                  .content-text {
                    width: 245px;
                  }
                }
              }
              @media screen and (max-width: 400px) {
                .year {
                  margin: 4.5em 0;
                }
              }
              .year.selected .content-text {
                margin-left: 2em;
                margin-right: 1.5em;
                font-size: 16px; /* Adjust the font size when selected */
                color: #000; /* Adjust the text color when selected */
                opacity: 1; /* Adjust the opacity when selected */
              }
              @media screen and (max-width: 550px) {
                .year.selected .content-text {
                  padding: 15px 15px !important;
                  max-width: 350px;
                }
              }
              @media screen and (max-width: 460px) {
                .year.selected .content-text {
                  max-width: 300px;
                }
              }
              @media screen and (max-width: 400px) {
                .year.selected .content-text {
                  max-width: 245px;
                }
              }
              .year:not(.selected) .content-text {
                font-size: 14px; /* Adjust the font size when deselected */
                color: #888; /* Adjust the text color when deselected */
                opacity: 0.7; /* Adjust the opacity when deselected */
              }
            }
          }
          .years-inner-left {
            .dot-year-wrapper {
              .year {
                justify-content: flex-end;
                transition: 0.5s;
                .year-text-wrapper {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                }
                @media screen and (max-width: 1000px) {
                  .year-text-wrapper {
                    .year-num {
                      margin-right: 0.8em;
                    }
                  }
                }
              }
              .year.selected .year-text-wrapper {
                .year-num {
                  margin-right: 0.4em;
                }
              }
              .year.selected .content-text {
                border-radius: 5px;
                padding: 30px 22px;
                // background-color: red;
                color: #000;
                font-family: Roboto;
                font-weight: 400px;
                font-size: 14px;
                width: 400px;
                text-decoration: normal;
                font-style: normal;
                transition: font-size 0.5s, color 0.5s, opacity 0.5s;
                position: relative;
              }
              .year.selected .content-text:before {
                content: "";
                position: absolute;
                left: 100%;
                top: 50%;
                transform: translate(0%, -50%);
                width: 0;
                height: 0;
                border-top: 13px solid transparent;
                border-left: 26px solid #fff;
                border-bottom: 13px solid transparent;
              }
            }
          }
          @media screen and (max-width: 1000px) {
            .years-inner-left {
              display: none;
            }
          }
          .years-inner-right {
            .dot-year-wrapper {
              .year {
                justify-content: flex-start;
                // Dot
                .dot {
                  margin-right: -0.2em;
                  margin-left: -21px;
                }
                .year-text-wrapper {
                  margin-left: -5px;
                  .year-num {
                    margin-left: 0.6em;
                  }
                }
              }
              .year.selected .year-text-wrapper {
                .year-num {
                  margin-left: 0.6em;
                }
              }
              .year.selected .content-text {
                border-radius: 5px;
                padding: 30px 22px;
                // background-color: red;
                color: #000;
                font-family: Roboto;
                font-weight: 400px;
                font-size: 14px;
                width: 400px;
                text-decoration: normal;
                font-style: normal;
                transition: font-size 0.5s, color 0.5s, opacity 0.5s;
                position: relative;
              }
              .year.selected .content-text:before {
                content: "";
                position: absolute;
                right: 100%;
                top: 50%;
                transform: translate(0%, -50%);
                width: 0;
                height: 0;
                border-top: 13px solid transparent;
                border-right: 26px solid #fff;
                border-bottom: 13px solid transparent;
              }
            }
          }
          .mid-line {
            width: 1px;
            background-color: #fff;
          }
        }
      }
      @media screen and (max-width: 1000px) {
        .columns-wrapper {
          justify-content: flex-start;
          margin-left: 7em;
        }
      }
      @media screen and (max-width: 700px) {
        .columns-wrapper {
          margin-left: 3em;
        }
      }
      @media screen and (max-width: 550px) {
        .columns-wrapper {
          margin-left: 1.5em;
        }
      }
    }
  }
  