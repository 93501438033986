.react-responsive-carousel {
  width: 800px;
  height: 600px;
  overflow: hidden;
  background-color: rgb(231, 231, 231);
  .image-wrapper {
    width: 800px;
    height: 600px;
    // width: 100%;
    // height: 100%;
    display: flex;
    align-items: center;
    .carousel-image {
      height: 100%;
    }
  }
}
@media screen and (max-width: 865px) {
  .react-responsive-carousel {
    width: 700px;
    height: 550px;
    .image-wrapper {
      width: 700px;
      height: 550px;
    }
  }
}

@media screen and (max-width: 747px) {
  .react-responsive-carousel {
    width: 600px;
    height: 500px;
    .image-wrapper {
      width: 600px;
      height: 500px;
    }
  }
}

@media screen and (max-width: 635px) {
    .react-responsive-carousel {
      width: 550px;
      .image-wrapper {
        width: 550px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .react-responsive-carousel {
      width: 500px;
      .image-wrapper {
        width: 500px;
      }
    }
  }
  @media screen and (max-width: 521px) {
    .react-responsive-carousel {
      width: 450px;
      .image-wrapper {
        width: 450px;
      }
    }
  }
  @media screen and (max-width: 469px) {
    .react-responsive-carousel {
      width: 380px;
      .image-wrapper {
        width: 380px;
      }
    }
  }
  @media screen and (max-width: 405px) {
    .react-responsive-carousel {
      width: 340px;
      .image-wrapper {
        width: 340px;
      }
    }
  }
  @media screen and (max-width: 355px) {
    .react-responsive-carousel {
      width: 320px;
      .image-wrapper {
        width: 320px;
      }
    }
  }
  @media screen and (max-width: 331px) {
    .react-responsive-carousel {
      width: 300px;
      .image-wrapper {
        width: 300px;
      }
    }
  }