.notice-card {
  height: 100%;
  min-height: 120px;
  .content {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    .card-img {
      height: 100%;
    }
    .text-area {
      display: flex;
      align-items: center;
      padding: 1em;
      .title {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: left;
        font-weight: 400;
        font-size: 15px;
        color: $primaryTitleDarker;
      }
    }
  }
  @media screen and (max-width: 550px) {
    .content {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
}
