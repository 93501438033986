.member-timeline-page {
  width: 100vw;
  display: flex;
  justify-content: center;
  //   padding-bottom: 2em;
  .contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .banner-section {
      display: flex;
      justify-content: center;
      height: 470px;
      height: 300px;
      width: 100%;
      background-color: #ffffff;
      .member-banner {
        padding-top: 2.5em;
        padding-bottom: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 1269px;
        gap: 0.5em;
        // position: relative;
        // justify-content: flex-end;
        .member-name {
          // top: 1em;
          // left: 50%;
          // transform: translate(-50%);
          font-size: 25px;
          font-weight: 600;
          // position: absolute;
        }
        .member-img {
          // margin-right: 3em;
          border-radius: 50%;
          // border-radius: 10px;
          // width: 350px;
          // height: 350px;
          width: 200px;
          height: 200px;
        }
      }
    }
    .timeline-section {
      //   margin-top: 2em;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: max-content;
      .title-wrapper {
        text-align: center;
        .member-name {
          font-weight: 600;
          font-size: 20px;
        }
      }
    }
    .spacer {
      height: 120px;
      width: 100%;
      background-color: #fff;
    }
  }
}

// @media screen and (max-width: 600px) {
//   .member-timeline-page {
//     margin-top: 1.5em;
//     padding-bottom: 1em;
//   }
// }

@media screen and (max-width: 1268px) {
  .contents {
    width: 100%;
  }
}
