.css-1m5f78l {
  height: 100% !important;
}

// ----------------------------------
.news-card {
  width: 100%;
  // height: 100%;
  // height: max-content;
  // max-height: 445px;
  .css-o69gx8-MuiCardMedia-root {
    // height: max-content;
    max-height: 300px;
    min-height: 300px;
  }
  // .card-image {
  //   // height: max-content;
  //   // height: 900px;
  //   // height: 70%;
  //   min-height: 300px;
  // }
  .text-section {
    height: max-content;
    margin: 0.2em;
    min-height: 160px;
    max-height: 160px;
    .title {
      padding-top: 0.1em;
      font-size: 22px;
      font-weight: 600;
      color: $primaryTitle;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .desc {
      // margin-bottom: 0.5em;
      line-height: 30px;
      font-size: 1em;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    
  }
  @media screen and (max-width: 901px) {
    .text-section {
      // min-height: 215px;
      // max-height: 215px;
      min-height: 185px;
      max-height: 185px;
      .desc {
        -webkit-line-clamp: 3; /* number of lines to show */
        line-clamp: 3;
      }
    }
  }
  @media screen and (max-width: 765px) {
    .text-section {
      // min-height: 155px;
      // max-height: 155px;
      min-height: 155px;
      max-height: 155px;
      .desc {
        -webkit-line-clamp: 2; /* number of lines to show */
      }
    }
  }
  @media screen and (max-width: 449.5px) {
    .text-section {
      // min-height: 215px;
      // max-height: 215px;
      min-height: 185px;
      max-height: 185px;
      .desc {
        -webkit-line-clamp: 3; /* number of lines to show */
      }
    }
  }
  @media screen and (max-width: 357.5px) {
    .text-section {
      // min-height: 215px;
      // max-height: 215px;
      min-height: 160px;
      max-height: 160px;
      .desc {
        -webkit-line-clamp: 2; /* number of lines to show */
      }
    }
  }
}


// --------------
