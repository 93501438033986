

.spinner-wrapper {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(212, 212, 212, 0.228);
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // .spinner {
  // top: 0;
  // left: 0;
  // position: absolute;
  // }
}


.css-1x7skt0 {
  background-color: #fff !important;
}
.css-1hut38s{
  color: black;
}
.header .nav-wrapper .right .search-bar .search-icon{
  color: grey;
}

.header {

  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 99;
  top: 0;
  font-style: normal;
  font-family: "Gotu", sans-serif;
  .css-hip9hq-MuiPaper-root-MuiAppBar-root {
    padding-bottom: 0.5em;
    background-color: #fff;
    width: 100vw;
    color: #000;
    .css-i4bv87-MuiSvgIcon-root {
      color: $primaryGrey !important;
    }
    .css-7mt4h1-MuiInputBase-root {
      color: #000;
      border-radius: 5px;
    }
  }
  .css-yz9k0d-MuiInputBase-input {
    font: caption;
    font-size: 15px;
    // font-style: normal !important;
  }
  //   ------------------------------
  .top-border {
    display: flex;
    justify-content: center;
    padding-bottom: 0.5em;
    .top-border {
      margin-top: 0;
      margin: 0;
      // padding: 0 0.7em;
      padding: 0;
      width: 1269px;
    }
  }
  .nav-wrapper {
    align-self: center;
    width: 1269px;
    // padding: 0 0.6em ;
    padding: 0;
    .left {
      display: flex;
      align-items: center;
      .company-logo {
        cursor: default;
        margin-left: 9px;
        width: 70px;
        height: 70px;
      }
      .company-name {
        cursor: default;
        color: $primaryRed;
        margin-left: 15px;
        font-size: 28px;
        font-weight: 600;
      }
    }
    .right {
      margin-left: auto;
      display: flex;
      align-items: center;
      .search-bar {
        display: flex;
        align-items: center;
        margin-left: 0.7em;
        .search-icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
          position: absolute;
          z-index: 1;
          left: 0.6em;
          transition: 0.5s;
          &:hover {
            color: grey;
          }
        }
      }
      .mail-wrapper {
        margin-left: 1em;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $primaryGrey;
        border-radius: 50%;
        min-width: 28px;
        min-height: 28px;
        cursor: pointer;
        .mail-icon {
          color: $primaryGrey;
          width: 20.5px;
          height: 20.5px;
        }
      }
      .toggle-icon-wrapper {
        margin-right: 1px;
        margin-left: 15px;
        .toggle-icon {
          color: #000;
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  @media screen and (max-width: 729px) {
    .nav-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .left {
        .company-name {
          cursor: default;
          margin-left: 8px;
        }
      }
      .divider {
        border-bottom: 1px solid rgb(206, 206, 206);
        width: 93.5%;
        align-self: center;
        margin: 5px 0;
      }
      .right {
        .mail-wrapper {
          margin-right: -0.5em;
          margin-left: 0.8em;
        }
        .toggle-icon-wrapper {
          margin-left: -5px;
        }
      }
    }
  }
  @media screen and (max-width: 410px) {
    .nav-wrapper {
      .left {
        .company-logo {
          width: 60px;
          height: 60px;
        }
        .company-name {
          font-size: 22px;
        }
      }
    }
  }
  @media screen and (max-width: 1270px) {
    .top-border {
      .top-border {
        width: 100%;
        padding: 0;
      }
    }
    .nav-wrapper {
      width: 100%;
    }
  }
}
.css-1orzy9s {
  background-color: rgb(230, 230, 230) !important;
}
.css-i4bv87-MuiSvgIcon-root {
  color: #000 !important;
}

// -------------sidebar---------------
.sidebar {
  .company-title-wrapper {
    display: flex;
    align-items: center;
    gap: 0.7em;
    cursor: pointer;
    .company-logo {
      height: 50px;
      height: 50px;
    }
    .company-name {
      font-size: 23px;
      font-weight: 400;
      color: $primaryRed;
    }
  }
  .btn-close {
    margin-right: 0px;
  }
  .hr-line {
    margin-top: -0em;
    margin: 0 1.5em;
  }
  .items-wrapper {
    margin-top: 0.4em;
    padding-left: 1.5em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    font-size: 20px;
    .nav-item {
      display: flex;
      align-items: center;
      gap: 1.2em;
      text-decoration: none;
      color: $primaryGrey;
      cursor: pointer;
      &:hover {
        color: #000;
      }
      .nav-icon {
        height: 25px;
        width: 25px;
      }
    }
  }
}
