@import "./homeCarousel";
@import "./newsSection";
@import "./videosSection";
@import "./noticeSection";
@import "./contactSection";

.home-page {
  width: 100vw;

  display: flex;
  justify-content: center;
  .contents {
    text-align: justify;
    .carousel-wrapper {
      display: flex;
      justify-content: center;
    }
  }
  margin-bottom: 3em;
}
