.overlay-wrapper {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .overlay {
    background-color: rgba(128, 128, 128, 0.393);
    width: 100vw;
    height: 100vh;
  }
  .close-icon {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 1em;
    top: 1em;
    cursor: pointer;
    &:hover {
      color: #fff;
    }
  }
  .carousel-comp-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: max-content;
    height: max-content;
    transform: translate(-50%, -50%);
    .carousel-comp {
      padding: 0;
      margin: 0;
    }
  }
}

.gallery-page {
  display: flex;
  justify-content: center;
  padding-bottom: 2em;
  margin-bottom: 2em;
  .contents {
    padding: 1em 0.7em;
    width: 1269px;
    .heading-wrapper {
      margin-top: 2.5em;
      border-bottom: 1px solid #000;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .title {
        font-size: 50px;
        font-weight: 600;
      }
    }
    @media screen and (max-width: 600px) {
      .heading-wrapper {
        margin-top: 0;
        .title {
          font-size: 35px;
        }
      }
    }
    .gallery-wrapper {
      margin-top: 2em;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.5em;
      .square-notice-card {
        min-height: 300px;
        max-height: 300px;
        .content {
          width: 100%;
          .card-img {
            height: 100%;
            width: 100%;
          }
          .text-area {
            padding: 1em;
            .title {
              font-size: 18px;
              font-weight: 600;
              color: $primaryTitleDarker;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* number of lines to show */
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
    @media screen and (max-width: 1100px) {
      .gallery-wrapper {
        margin-top: 1em;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5em;
      }
    }
    @media screen and (max-width: 630px) {
      .gallery-wrapper {
        margin-top: 1em;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.5em;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .gallery-page {
    margin-top: 1.5em;
    padding-bottom: 1em;
  }
}

@media screen and (max-width: 1268px) {
  .contents {
    width: 100%;
  }
}
