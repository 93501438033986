.overlay-wrapper {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .overlay {
    // background-color: rgba(128, 128, 128, 0.393);
    width: 100vw;
    height: 100vh;
  }
  .close-icon {
    position: absolute;
    z-index: 2;
    width: 30px;
    height: 30px;
    right: 1em;
    top: 1em;
    cursor: pointer;
    &:hover {
      color: #fff;
    }
  }
  .react-player-wrapper {
    display: flex;
    z-index: 2;
    align-items: center;
    background-color: rgb(1, 1, 1);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 700px;
    height: 500px;
    transform: translate(-50%, -50%);
  }
  @media screen and (max-width: 750px) {
    .react-player-wrapper {
      width: 600px;
    }
  }
  @media screen and (max-width: 630px) {
    .react-player-wrapper {
      width: 500px;
      height: 400px;
    }
  }
  @media screen and (max-width: 520px) {
    .react-player-wrapper {
      width: 400px;
    }
  }
  @media screen and (max-width: 430px) {
    .react-player-wrapper {
      width: 350px;
    }
  }
  @media screen and (max-width: 365px) {
    .react-player-wrapper {
      width: 300px;
    }
  }
  @media screen and (max-width: 311px) {
    .react-player-wrapper {
      width: 250px;
    }
  }
}

.videos {
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
  padding-bottom: 2em;
  .contents {
    padding: 1em 0.7em;
    width: 1269px;
    .heading-wrapper {
      margin-top: 2.5em;
      border-bottom: 1px solid #000;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .title {
        font-size: 50px;
        font-weight: 600;
      }
      // .see-more {
      //   cursor: pointer;
      //   color: #000;
      //   font-size: 18px;
      // }
    }
    @media screen and (max-width: 600px) {
      .heading-wrapper {
        margin-top: 0;
        .title {
          font-size: 35px;
        }
        //   .see-more {
        //     font-size: 15px;
        //   }
      }
    }
    // .heading {
    //   font-size: 50px;
    //   font-weight: 600;
    //   border-bottom: 1px solid #000;
    // }
    // @media screen and (max-width: 600px) {
    //   .heading {
    //     font-size: 35px;
    //   }
    // }
    .videos-wrapper {
      margin-top: 2em;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.5em;
      .video-card {
        
        position: relative;
        .react-player {
          width: 100%;
          height: 100%;
        }
        .player-overlay {
          cursor: pointer;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 1;
        }
      }
    }
    @media screen and (max-width: 1220px) {
      .videos-wrapper {
        margin-top: 1em;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5em;
      }
    }
    @media screen and (max-width: 765px) {
      .videos-wrapper {
        margin-top: 1em;
        display: grid;
        grid-template-columns: 1fr;
        gap: 1.5em;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .videos {
    margin-top: 1.5em;
    padding-bottom: 1em;
  }
}

@media screen and (max-width: 1268px) {
  .contents {
    width: 100%;
  }
}
