.home-carousel {
  margin-top: 1.5em;
  padding: 1em 0.7em;
  width: 1269px;
  overflow: hidden;
  .image-wrapper {
    height: 100%;
    .carousel-image {
      min-height: 100%;
      max-height: 530px;
    }
  }
}
@media screen and (max-width: 600px) {
  .home-carousel {
  margin-top: 0.5em;
    
  }
}
